const validResponseForTypeMap = {
  Textbox: value => value !== '',
  YesNoRadioGroup: value => value === 'Yes' || value === 'No',
  IHaveReadItCheckbox: value => value === 'true',
  IAgreeCheckbox: value => value === 'true',
  NumericDropdown: value => value !== ''
};

export function validateResponses(disclosureQuestions) {
  return disclosureQuestions
    .filter(q => q.requiresResponse)
    .every(q => {
      var childResponsesAreValid = true;
      if (q.controlTypeName === 'YesNoRadioGroup' && q.response === 'Yes' && q.children.length > 0) {
        childResponsesAreValid = validateResponses(q.children);
      }
      return childResponsesAreValid && validResponseForTypeMap[q.controlTypeName](q.response);
    });
}
