import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../common';
import { UPDATE_DISCLOSURE_QUESTION } from '../provider/disclosureQuestionReducers';
import { I_AGREE_CHECKBOX } from '../disclosure-question-validations';

const IAgreeCheckbox = ({ sourceName, value, isSubmitted, controlProps, ...props }) => {
  const isChecked = value === 'true';
  const IAgreeCheckboxId = `${I_AGREE_CHECKBOX}-${sourceName}`;
  const { validationResult, handleBlur, handleChange, dispatchDisclosureQuestions } = controlProps;

  return (
    <Checkbox
      {...props}
      name={IAgreeCheckboxId}
      onBlur={handleBlur}
      onChange={event => {
        handleChange(event);
        dispatchDisclosureQuestions({
          type: UPDATE_DISCLOSURE_QUESTION,
          payload: {
            sourceName,
            value: event.target.checked
          }
        });
      }}
      disabled={isSubmitted}
      checked={isChecked}
      data-testid="i-agree-checkbox"
      // TODO: refactor to use optional-chaining library
      errorMessage={validationResult && validationResult[IAgreeCheckboxId].errorMessage}
      validationState={validationResult && validationResult[IAgreeCheckboxId].validationState}
    >
      <b>Agreed</b>
    </Checkbox>
  );
};

IAgreeCheckbox.propTypes = {
  sourceName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  controlProps: PropTypes.object
};

export default IAgreeCheckbox;
