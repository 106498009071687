import React from 'react';
import PropTypes from 'prop-types';
import { MultilineText } from '../../../common';

export const QuestionRow = ({ subQuestions, displayNumber, text, response, whereToRender, level = 0, controlTypeName }) => {
  if (whereToRender !== 'disclosureQuestion') return null;
  if (controlTypeName.toLowerCase() === 'message') return null;

  const convertResponseToText = (response) => {
    if (response === 'true') {
      return controlTypeName === 'IAgreeCheckbox' ? 'Agreed' : 'I have read it';
    }
    if (response === 'false') {
      return controlTypeName === 'IAgreeCheckbox' ? 'Not agreed' : 'Not read';
    }
    return response;
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div style={{ marginLeft: 20 * level }} >
            {displayNumber ? `${displayNumber}. ` : ''}<MultilineText value={text} />
          </div>
        </div>
        <div className="col-3">{convertResponseToText(response)}</div>
      </div>
      {
        response.toLowerCase() === 'yes' &&
        subQuestions &&
        subQuestions.map((element) => {
          return (
            <QuestionRow
              key={element.questionId}
              subQuestions={element.children}
              level={level + 1}
              {...element}
            />
          );
        })
      }
    </>
  );
};

QuestionRow.propTypes = {
  displayNumber: PropTypes.string,
  text: PropTypes.string,
  subQuestions: PropTypes.array,
  response: PropTypes.any,
  level: PropTypes.number,
  whereToRender: PropTypes.string,
  controlTypeName: PropTypes.string,
};
