import React from 'react';
import TextInput from '../../common/TextInput';
import Header from './Header';
import YesNoRadioGroup from './YesNoRadioGroup';
import BulletPoint from './BulletPoint';
import NumericDropdown from './NumericDropdown';
import IHaveReadItCheckbox from './IHaveReadItCheckbox';
import IAgreeCheckbox from './IAgreeCheckbox';
import { UPDATE_DISCLOSURE_QUESTION } from '../provider/disclosureQuestionReducers';
import { TEXTBOX } from '../disclosure-question-validations';

/*eslint-disable */
export default {
  Header: ({ text }) => <Header text={text} />,
  Label: ({ text }) => <label>{text}</label>,
  Textbox: ({ sourceName, value, disabled = false, controlProps }) => {
    const { handleBlur, validationResult, dispatchDisclosureQuestions } = controlProps;
    const textBoxId = `${TEXTBOX}-${sourceName}`
    return (
      <TextInput
        value={value}
        onChange={event =>
          dispatchDisclosureQuestions({
            type: UPDATE_DISCLOSURE_QUESTION,
            payload: {
              sourceName,
              value: event.target.value
            }
          })
        }
        onBlur={handleBlur}
        label=""
        validationState={validationResult[textBoxId].validationState}
        errorMessage={validationResult[textBoxId].errorMessage}
        name={textBoxId}
        data-testid="text-box"
        disabled={disabled}
      />
    );
  },
  YesNoRadioGroup: ({ sourceName, value, isSubmitted, controlProps }) =>
    <YesNoRadioGroup
      sourceName={sourceName}
      value={value}
      isSubmitted={isSubmitted}
      controlProps={controlProps}
    />,
  BulletPoint: ({ text }) => <BulletPoint text={text} />,
  NumericDropdown: ({ sourceName, value, controlProps }) =>
    <NumericDropdown
      sourceName={sourceName}
      value={value}
      controlProps={controlProps}
    />,
  IHaveReadItCheckbox: ({ sourceName, value, isSubmitted, controlProps }) =>
    <IHaveReadItCheckbox
      sourceName={sourceName}
      value={value}
      isSubmitted={isSubmitted}
      controlProps={controlProps}
    />,
  IAgreeCheckbox: ({ sourceName, value, isSubmitted, controlProps }) =>
    <IAgreeCheckbox
      sourceName={sourceName}
      value={value}
      isSubmitted={isSubmitted}
      controlProps={controlProps}
    />,
  Link: ({ text }) => <a href={text} target="_blank">{text}</a>
};
